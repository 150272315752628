import { Space, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import styles from './OvertflowText.module.css';
import { TooltipPlacement } from 'antd/es/tooltip';

interface OverflowTextProps {
  children: JSX.Element | React.ReactNode;
  placement?: TooltipPlacement;
}

const OverflowText: React.FC<OverflowTextProps> = (props) => {
  const { children, placement = 'right' } = props;

  const [overflowed, setOverflowed] = useState<boolean>(false);

  return (
    <div
      className={styles.container}
      ref={(divRef) => {
        if (divRef) {
          const span: any = divRef.querySelector('span');
          if (span) {
            if (span.offsetWidth > divRef.offsetWidth) {
              setOverflowed(true);
            }
          }
        }
      }}
    >
      <span>
        {overflowed ? (
          <Tooltip
            color={'var(--color-light)'}
            overlayClassName={styles.tooltip}
            placement={placement}
            title={<Space direction='vertical'>{children}</Space>}
          >
            {children}
          </Tooltip>
        ) : (
          children
        )}
      </span>
    </div>
  );
};

export default OverflowText;
