import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import { PlotMouseEvent, ScatterData } from 'plotly.js';
import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import Tooltip from './Tooltip/Tooltip';
import { IChartData } from 'interfaces/ChartData';
import PlaceholderChart from './PlaceholderChart/PlaceholderChart';
import styles from './Chart.module.css';
import { useTranslation } from 'react-i18next';

const Chart: React.FC<BaseComponentProps> = (props) => {
  const [chartData, setChartData] = useState<Partial<ScatterData>[] | null>(null);
  const [datesArray, setDatesArray] = useState<string[]>();
  const [lineColor, setLineColor] = useState('');
  const [tooltipContent, setTooltipContent] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
    left: boolean;
  } | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const hideTooltipTimeout = useRef<NodeJS.Timeout | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const root = document.documentElement;

    const color = getComputedStyle(root).getPropertyValue('--color-primary').trim();

    setLineColor(color);
  }, []);

  useEffect(() => {
    if (props.value) {
      const legendMap = new Map<string, string>();

      const tasks = props.value as IChartData;
      const datesArrayTemp: string[] = [];

      const traces: Partial<ScatterData>[] = tasks.data.map((task) => {
        const x: string[] = [];
        const y: string[] = [];
        const symbols: string[] = [];
        const texts: string[] = [];
        const markerColor: string[] = [];
        task.events.forEach((event) => {
          x.push(event.date);
          y.push(task.name);
          symbols.push(event.symbol);
          texts.push(event.hint ?? event.text);
          markerColor.push(event.color ?? lineColor);
          legendMap.set(event.symbol, event.text);
          datesArrayTemp.push(event.date);
        });
        return {
          x,
          y,
          mode: 'lines+markers',
          type: 'scatter',
          marker: {
            symbol: symbols,
            size: 14,
            color: markerColor,
          },
          line: {
            width: 2,
            color: lineColor,
          },
          name: task.name,
          text: texts,
          showlegend: false,
          hoverinfo: 'none',
        };
      });

      if (tasks.startDate && tasks.endDate) {
        setDatesArray([tasks.startDate, tasks.endDate]);
      } else {
        const dates: Date[] = datesArrayTemp.map((date) => new Date(date));
        // Найдем минимальную и максимальную дату
        const tenDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
        const minDate = new Date(Math.min(...dates.map((date) => date.getTime())));
        const maxDate = new Date(Math.max(...dates.map((date) => date.getTime())));

        minDate.setTime(minDate.getTime() - tenDaysInMilliseconds);
        maxDate.setTime(maxDate.getTime() + tenDaysInMilliseconds);

        setDatesArray([(minDate).toISOString(), maxDate.toISOString()]);
      }

      const legendData: Partial<ScatterData>[] = Array.from(legendMap).map(([symbol, name]) => ({
        x: [null],
        y: [null],
        mode: 'lines+markers',
        type: 'scatter',
        line: {
          width: 0,
        },
        name,
        marker: {
          symbol,
          size: 14,
          color: lineColor,
        },
        showlegend: true,
      }));

      setChartData(traces.concat(...legendData));
    }
  }, [props.value]);

  const handleHover = (event: PlotMouseEvent) => {
    event.event.stopPropagation();
    if (hideTooltipTimeout.current) {
      clearTimeout(hideTooltipTimeout.current);
      hideTooltipTimeout.current = null;
    }
    const point = event.points[0] as any; // Берём данные первой точки
    const target = event.event.target as HTMLDivElement;
    if (target.getBoundingClientRect().x + point.bbox.x0 - 30 < window.innerWidth - 300) {
      setTooltipPosition({
        x: target.getBoundingClientRect().x + point.bbox.x0 - 30,
        y: target.getBoundingClientRect().y + point.bbox.y0 - 10,
        left: false,
      });
    } else {
      setTooltipPosition({
        x: window.innerWidth - (target.getBoundingClientRect().x + point.bbox.x0 - 60),
        y: target.getBoundingClientRect().y + point.bbox.y0 - 10,
        left: true,
      });
    }

    setTooltipContent(`${point.text}`);
    setTooltipVisible(true);
  };

  const handleUnhover = () => {
    hideTooltipTimeout.current = setTimeout(() => {
      if (tooltipRef.current && !tooltipRef.current.matches(':hover')) {
        setTooltipVisible(false);
      }
    }, 200);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div>
      {chartData ? (
        <Plot
          data={chartData}
          onHover={handleHover}
          onUnhover={handleUnhover}
          layout={{
            autosize: true,
            height: chartData.filter((trace) => !trace.showlegend).length * 100 + 120,
            shapes: [
              {
                type: 'line',
                x0: new Date(),
                x1: new Date(),
                y0: 0,
                y1: chartData.filter((trace) => !trace.showlegend).length,
                line: {
                  color: 'black',
                  width: 2,
                  dash: 'dash',
                },
              },
            ],
            xaxis: {
              type: 'date',
              range: datesArray,
              showgrid: true,
              tickformat: '%d.%m.%Y', 
              gridcolor: 'white',
            },
            yaxis: {
              showgrid: true,
              type: 'category',
              autorange: 'reversed',
              gridcolor: 'white',
              fixedrange: true
            },
            legend: {
              orientation: 'h',
              x: 0.5,
              y: 0,
              xanchor: 'center',
              itemclick: false,
              itemdoubleclick: false,
              yanchor: 'bottom',
              yref: 'container',
            },
            margin: {
              l: 50,
              r: 0,
              t: 0,
              b: 120,
            },
            plot_bgcolor: '#E5ECF6',
            paper_bgcolor: 'rgba(0, 0, 0, 0)',
            showlegend: true,
            dragmode: 'zoom',
          }}
          useResizeHandler={true}
          style={{ width: '100%', height: '100%' }}
          config={{
            displayModeBar: false,
            scrollZoom: true,
            doubleClick: false,
            displaylogo: false,
            showAxisDragHandles: true,
          }}
        />
      ) : (
        <div className={styles.placeholderContainer}>
          <PlaceholderChart />
          <div className={styles.placeholderText}>{t('chartPlaceholder')}</div>
          <div className={styles.placeholderTextPart2}>{t('chartPlaceholderPart2')}</div>
        </div>
      )}
      {tooltipVisible && (
        <Tooltip
          onMouseLeave={handleMouseLeave}
          content={tooltipContent}
          position={tooltipPosition}
          ref={tooltipRef}
        />
      )}
    </div>
  );
};

export default Chart;
