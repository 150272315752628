import Icon from '@ant-design/icons';
import { BaseIcon } from 'interfaces/BaseIcon';

const IconSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 40 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20 0C11.4286 0 4.28571 5.33333 0 12C4.28571 18.6667 11.4286 24 20 24C28.5714 24 35.7143 18.6667 40 12C35.7143 5.33333 28.5714 0 20 0ZM20 20.6667C14.8714 20.6667 10.7143 16.7867 10.7143 12C10.7143 7.21333 14.8714 3.33333 20 3.33333C25.1286 3.33333 29.2857 7.21333 29.2857 12C29.2857 16.7867 25.1286 20.6667 20 20.6667Z'
      fill='#4067BF'
    />
    <path
      d='M19.9994 6.66797C19.128 6.66797 18.3109 6.86397 17.5709 7.19064C18.4009 7.46664 18.9994 8.19864 18.9994 9.06797C18.9994 10.1733 18.0409 11.068 16.8566 11.068C15.9252 11.068 15.1409 10.5106 14.8452 9.73464C14.4952 10.4253 14.2852 11.188 14.2852 12.0013C14.2852 14.9466 16.8437 17.3346 19.9994 17.3346C23.1552 17.3346 25.7137 14.9466 25.7137 12.0013C25.7137 9.05597 23.1552 6.66797 19.9994 6.66797Z'
      fill='#4067BF'
    />
  </svg>
);

const CustomIcon = (props: BaseIcon) => {
  const { active, ...iconProps } = props;
  return <Icon component={IconSvg} {...iconProps} />;
};

export default CustomIcon;
