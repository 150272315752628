import { Badge, Space, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import stylesTable from 'components/TableGrid/index.module.css';
import FiltersTooltip from 'components/filtersTooltip/FiltersTooltip';
import FilteredIcon from 'components/icons/FilteredIcon';
import DeleteIcon from 'components/icons/table/DeleteIcon';
import useModalForm from 'hooks/useModalForm';
import { isArray, isNumber } from 'lodash';
import { handleIcon } from 'pages/entityList/iconsForStatus';
import { widthForMaxSize, widthForMiddleSize, widthForSmallSize } from 'pages/entityList/utils';
import { MutableRefObject, useCallback, useEffect } from 'react';
import modal from 'components/messages/modal';
import { TableConfiguration, TableField } from '../../../interfaces/EntityList';
import styles from '../../../pages/entityList/EntityList.module.css';
import { handleWidthForTableCell, tryParsePercentage } from '../utils/size';
import { LinkContainer, LinkContainerPropsType } from '../components/LinkContainer';
import { TagLayout } from '../components/TagLayout';
import useModalWithText from 'hooks/useModalWithText';
import { DataModal } from 'utils/types';
import { useTranslation } from 'react-i18next';
import { ControlType } from 'interfaces/BaseComponentInterface';
import OverflowText from 'components/content/OverflowText/OverflowText';

type UseUpdateColumnsArgsType = {
  tableConfiguration: any;
  setColumns: any;
  knowledgeBaseDelete: any;
  width: number;
  filtersForm: any;
  componentData: any;
  tableIdRef: MutableRefObject<string>;
  tableViewDeleteRow?: (record: any) => void;
};

type UseUpdateColumnsReturnsType = {
  updateColumns: any;
};

type UseUpdateColumnsType = (args: UseUpdateColumnsArgsType) => UseUpdateColumnsReturnsType;

export const useTableViewUpdateColumns: UseUpdateColumnsType = ({
  setColumns,
  knowledgeBaseDelete,
  width,
  filtersForm,
  componentData,
  tableIdRef,
  tableViewDeleteRow,
}) => {
  const { showModalEdit, showModalReadOnly, showModalCopy } = useModalForm();
  const { getDataModal } = useModalWithText();
  const { choiceLists, refreshID } = componentData;

  const { t } = useTranslation();

  const updateColumns = useCallback(
    (
      tableConfiguration: TableConfiguration,
      isKnowledgeBase: boolean | null,
      isExternalUser: boolean | null,
      filtersConfiguration: any
    ) => {
      const tableColumns: ColumnType<TableField>[] = [];

      if (
        tableConfiguration?.fields &&
        filtersConfiguration !== undefined &&
        choiceLists !== undefined
      ) {
        filtersConfiguration.initConfig.criteria.conditions.forEach((param: any) => {
          filtersForm.setFieldValue(param.field, param.value);
        });

        tableConfiguration.fields.forEach((field: TableField, index: number) => {
          if (field.hidden) return;
          const showMultiSelectionValues: boolean = field.jsonConfig
            ? JSON.parse(field.jsonConfig).inboxDisplay?.showMultiSelectionValues
            : false;
          const secondProperty = field.jsonConfig
            ? JSON.parse(field.jsonConfig).cardDisplay?.select?.secondProperty
            : false;

          const newObj: any = {
            title:
              field.renderer === ControlType.TIME_INPUT
                ? field.label.replace('=>', ': ')
                : (
                    <OverflowText placement='leftBottom'>{field.label}</OverflowText>
                ),
            dataIndex: secondProperty ? secondProperty : field.fieldName,
            className: showMultiSelectionValues ? styles.dontOverflowColumn : '',
            render: (fieldName: any, record: any) => {
              if (isNumber(fieldName)) fieldName = String(fieldName);

              if (!fieldName) {
                return ``;
              }

              if (showMultiSelectionValues) {
                if (typeof fieldName === 'string')
                  return <TagLayout tagText={fieldName} key={index} />;
                if (isArray(fieldName)) {
                  return (
                    <>
                      {fieldName.map((cellFilling: string, index: number) => (
                        <TagLayout tagText={cellFilling} key={index} />
                      ))}
                    </>
                  );
                }
              }

              const linkContainerProps: LinkContainerPropsType = {
                record,
                field,
                showModalEdit,
                showModalReadOnly,
                showModalCopy,
                fieldName,
                refreshID,
              };

              if (field.renderer === 'choiceIcon') {
                linkContainerProps.fieldName = fieldName.label;
                return (
                  <div className={styles.iconWrapper}>
                    {handleIcon(fieldName.label)}{' '}
                    <div className={styles.overflowText}>
                      <LinkContainer {...linkContainerProps} />
                    </div>
                  </div>
                );
              }

              if (!!field.jsonConfig) {
                const parsedConfig = JSON.parse(field.jsonConfig);
                if (!!parsedConfig.styleTag) {
                  const recordValue = linkContainerProps?.record[`.key.${field?.fieldName}`];
                  return (
                    <div className={styles.iconWrapper}>
                      {!!recordValue && (
                        <Badge
                          color={parsedConfig.styleTag.indicator[recordValue].color}
                          style={{ marginRight: 5 }}
                        />
                      )}
                      <div className={styles.overflowText}>
                        <LinkContainer {...linkContainerProps} />
                      </div>
                    </div>
                  );
                }
              }

              if (
                index === 0 &&
                record.isKwonledgeBase === true &&
                isKnowledgeBase &&
                !isExternalUser
              ) {
                return (
                  <div className={styles.wrapperBase}>
                    <Tooltip
                      color={'var(--color-light)'}
                      overlayClassName={styles.tooltip}
                      title={<Space direction='vertical'>{t('requestInKnowledgeBase')}</Space>}
                    >
                      <div className={styles.knowledgeBase} />
                    </Tooltip>
                    <div className={styles.overflowText}>
                      <LinkContainer {...linkContainerProps} />
                    </div>
                  </div>
                );
              }

              return <LinkContainer {...linkContainerProps} />;
            },
          };
          newObj.width = tryParsePercentage(field.width);
          if (!newObj.width) {
            const customWidthConfig =
              width >= 2071
                ? widthForMaxSize
                : width > 1500
                ? widthForMiddleSize
                : widthForSmallSize;
            handleWidthForTableCell(customWidthConfig, field, newObj);
          }

          if (field.sortable) {
            newObj.sorter = true;
            newObj.sortDirections = ['descend', 'ascend'];
          }
          if (field.isFiltered) {
            newObj.filterDropdown = (props: any) => {
              newObj.filterIcon = (
                <FilteredIcon
                  active={
                    props.selectedKeys !== undefined &&
                    props.selectedKeys !== null &&
                    props.selectedKeys.length > 0
                  }
                  className={styles.icon}
                />
              );
              return (
                <FiltersTooltip
                  allFilters={filtersForm}
                  propsTooltip={props}
                  field={field}
                  filtersConfiguration={filtersConfiguration}
                  tableId={tableIdRef.current}
                />
              );
            };
          }
          tableColumns.push(newObj);
        });
      }
      if (tableConfiguration?.params?.deleteMark || tableConfiguration?.params?.tableDelete) {
        const dataModal: any = getDataModal(componentData.inboxName, width);
        dataModal.then((res: DataModal) => {
          tableColumns.push({
            fixed: 'right',
            width: 50,
            key: '_selected',
            className: stylesTable.checkboxColumn,
            render(value: any) {
              return (
                <div
                  className={'tableCheckbox'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const formData: FormData = new FormData();
                    formData.append('vsidArray', JSON.stringify([value.VersionSeriesId]));
                    formData.append('classNames', JSON.stringify([value.ClassName]));
                    modal.showModal({
                      onOk: () => {
                        if (tableConfiguration?.params?.deleteMark) {
                          knowledgeBaseDelete(formData);
                        } else if (tableConfiguration?.params?.tableDelete && tableViewDeleteRow) {
                          tableViewDeleteRow(value);
                        }
                      },
                      ...res,
                    });
                  }}
                >
                  <DeleteIcon />
                </div>
              );
            },
          });
        });
      }

      setColumns(tableColumns);
    },
    [filtersForm, knowledgeBaseDelete, choiceLists, setColumns, width]
  );

  return { updateColumns };
};
