import React, { forwardRef, useEffect, useState } from 'react';
import styles from './Tooltip.module.css';

interface TooltipProps {
  content: string | null;
  position: { x: number; y: number, left: boolean } | null;
  onMouseLeave: () => void;
}

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  ({ content, position, onMouseLeave }, ref) => {
    const [style, setStyle] = useState<React.CSSProperties>({});

    useEffect(() => {
      if (position) {
        if (position.left) {
          setStyle({
            right: `${position.x}px`,
            top: `${position.y}px`,
          });
        } else {
          setStyle({
            left: `${position.x}px`,
            top: `${position.y}px`,
          });
        }
      }
    }, [position]);

    if (!content) return null;

    return (
      <div
        id='#chartTooltip'
        className={styles.tooltipWrapper}
        onMouseLeave={onMouseLeave}
        style={style}
        ref={ref}
      >
        <div className={`${styles.arrow} ${position?.left && styles.arrowLeft}`}></div>
        <div dangerouslySetInnerHTML={{ __html: content }} className={styles.tooltip}></div>
      </div>
    );
  }
);

export default Tooltip;
