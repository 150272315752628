import { Button, Col, Form, Input, Row, Spin, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import FileModalPreview from 'components/controls/FilesList/FileModalPreview';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  withRouter,
  RouteComponentProps,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { AppState } from 'store/reducers';
import FormatUtils from 'utils/FormatUtils';
import styles from './Login.module.css';
import ModalLogin from './modalLogin/ModalLogin';
import { useWindowSize } from '@react-hook/window-size';
import { useKeycloak } from '@react-keycloak/web';
import stylesInput from '../controls/InputText/InputText.module.css';
import { EyeTwoTone } from '@ant-design/icons';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import notification from 'components/messages/notification';
import { useTranslation } from 'react-i18next';

interface LoginProps {
  user: any;
  setIsResetPass?: any;
  token: string;
}

export interface Agreement {
  VersionSeriesId: string;
  className: string;
  contentId: string | string[];
}

interface AuthResponse {
  Authorized: boolean;
  ConsentRequired?: boolean;
  Agreement?: Agreement;
}

const Login: React.FC<LoginProps & RouteComponentProps> = (props) => {
  const { setIsResetPass, token } = props;

  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [curTab, setCurTab] = useState<any>(0);
  const [width] = useWindowSize();
  const { keycloak } = useKeycloak();
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [formInstance] = useForm();
  const [formChangePassword] = useForm();
  const [subtitleForm, setSubtitleForm] = useState('');
  const {t} = useTranslation();

  const router = useRouteMatch();

  const msg = {
    title: [t("enterYourDetails"), t("enterANewPassword"), t("passwordRecovery")],
  };

  useEffect(() => {
    axios
      .get('/password-restore/disclaimer')
      .then((res: AxiosResponseExt) => {
        setSubtitleForm(res.data.message);
      })
      .catch((err) => {
        setSubtitleForm('Ошибка получения текста');
      });
  }, []);

  const handleNewPassword = (event: any) => {
    setNewPassword(event.target.value);
  };

  const handleRepeatNewPassword = (event: any) => {
    setRepeatNewPassword(event.target.value);
  };

  const goLogin = () => {
    setIsResetPass(false);
    history.push('/');
  };

  useEffect(() => {
    formInstance.resetFields();
    formInstance.setFieldsValue({
      username: '',
      password: '',
    });
  }, [formInstance]);

  const goToForgot = () => {
    setCurTab(1);
  };

  const goToLogin = () => {
    setCurTab(0);
  };

  const onFinishFailed = (errorInfo: any) => {
  };

  const changePassword = () => {
    const formData: FormData = new FormData();
    formData.append('new-password', newPassword || '');
    formData.append('repeated-password', repeatNewPassword || '');
    formData.append('token', token || '');
    axios
      .post('/password-restore/reset-pass', formData, {
        validateStatus: (status: number) => status >= 200 && status <= 500,
      })
      .then((res: AxiosResponseExt) => {
        if (res.status === 404) {
          notification.error({
            text: res.data.message,
            width,
          });
          goLogin();
        } else if (res.status === 400) {
          formChangePassword.setFields([
            {
              name: 'pw1',
              errors: res.data.password && [res.data.password],
            },
            {
              name: 'pw2',
              errors: res.data.repeatPassword && [res.data.repeatPassword],
            },
          ]);
        } else if (res.status === 200) {
          history.push('/password-restore/reset-pass/change-password');
          keycloak.login();
        }
      });
  };

  const title = msg.title[curTab];

  let form;
  switch (curTab) {
    case 1:
      form = (
        <>
          <div className={styles.title_password}>
            <span className={styles.title}>{title}</span>
          </div>
          <Form
            className={FormatUtils.getClassName([styles['form-item'], styles['form-item_content']])}
            name='forgot'
            form={formInstance}
            onFinish={goToLogin}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <div className={styles['form-item']}>
              <Form.Item
                name='pwd1'
                rules={[
                  {
                    required: true,
                    message: t("enterPassword"),
                  },
                ]}
              >
                <Input.Password autoComplete='new-password' placeholder={t("password")} />
              </Form.Item>
              <Form.Item
                name='pwd2'
                rules={[
                  {
                    required: true,
                    message: t("enterCorrectPassword"),
                  },
                ]}
              >
                <Input.Password autoComplete='new-password' placeholder={t("enterThePasswordAgain")} />
              </Form.Item>
            </div>
            <p className={styles.textForgot}>
              Пароль должен быть не менее 6 , но не более 14 символов. Разрешается использование
              букв только латинского алфавита. Обязательно наличие одной заглавной буквы и арабской
              цифры.
            </p>
            <div className={styles['form-item']}>
              <Button type='primary' htmlType='submit'>
                {t("save")}
              </Button>
              <span className={styles.forgot} onClick={() => goToLogin()}>
                {t("backToLogin")}
              </span>
            </div>
          </Form>
        </>
      );
      break;
    case 2:
      form = (
        <Form form={formInstance}>
          <div
            className={FormatUtils.getClassName([styles['form-item'], styles['form-item_title']])}
          >
            <span className={styles.title}>{title}</span>
          </div>
          <Form
            className={FormatUtils.getClassName([styles['form-item'], styles['form-item_content']])}
            name='agreement'
            onFinish={goToForgot}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <Form.Item className={FormatUtils.getClassName([styles['no-wrap']])}>
              <Input className={styles.input} placeholder='name@company.ru' />
              <p className={styles.textForm}>Укажите, связанный с вашим аккаунтом e-mail адрес</p>
            </Form.Item>
            <Row wrap={false}>
              <Col>
                <Button className={styles.btn} type='primary' htmlType='submit'>
                  Сбросить пароль
                </Button>
              </Col>
            </Row>
          </Form>
        </Form>
      );
      break;
    default:
      form = (
        <>
          <Form className={styles.formContainer} layout='vertical'>
            <span className={styles.inputWrap}>{t("enterANewPassword")}:</span>
            <Form.Item
              name={'pw1'}
              className={styles.formItem}
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: t("enterPassword"),
                  validateTrigger: 'onSubmit',
                },
                {
                  validateTrigger: 'onSubmit',
                  pattern: /(?=.*[0-9])(?=.*[\W,\_])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\W\_]/,
                  message:
                    'Пароль должен содержать все категории символов: строчные буквы, заглавные буквы, цифры, специальные символы.',
                },
                {
                  min: 8,
                  message: 'Пароль должен быть длиной не менее 8 символов.',
                  validateTrigger: 'onSubmit',
                },
                { pattern: /^\S*$/, message: 'Пароль не должен содержать пробелов.' },
              ]}
            >
              <Input.Password autoComplete='new-password'
                onPressEnter={changePassword}
                placeholder={t("enterPassword")}
                className={stylesInput.input}
                onChange={handleNewPassword}
                value={newPassword}
                suffix={<EyeTwoTone />}
              />
            </Form.Item>
            <span className={styles.inputWrap}>{t("confirmThePassword")}:</span>
            <Form.Item
              className={styles.formItem}
              name={'pw2'}
              rules={[
                {
                  required: true,
                  message: 'Введите пароль еще раз.',
                  validateTrigger: 'onSubmit',
                },
                ({ getFieldValue }) => ({
                  validateTrigger: 'onSubmit',
                  validator(_, value) {
                    if (!value || getFieldValue('pw1') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Пароли не совпадают.'));
                  },
                }),
              ]}
            >
              <Input.Password autoComplete='new-password'
                placeholder={t("enterPassword")}
                className={stylesInput.input}
                value={repeatNewPassword}
                onChange={handleRepeatNewPassword}
                suffix={<EyeTwoTone />}
                onPressEnter={changePassword}
              />
            </Form.Item>
          </Form>
        </>
      );
      break;
  }

  return (
    <>
      <>
        <Spin spinning={loading}>
          <div />
          <div className={styles.wrap}>
            <Modal
              className={`modal-withform ${styles.modal}`}
              title={t("changePassword")}
              visible={true}
              width='50%'
              centered={true}
              okText={t("save")}
              cancelText={t("cancel")}
              closeIcon={<></>}
              footer={[
                <div>
                  <Button key={1} type='primary' htmlType='button' onClick={changePassword}>
                    {t("save")}
                  </Button>
                  <Button key={1} type='primary' htmlType='button' onClick={goLogin}>
                    {t("cancel")}
                  </Button>
                </div>,
                <div className={styles.subtitlePassword}>{subtitleForm}</div>,
              ]}
            >
              <Form className={styles.formContainer} layout='vertical' form={formChangePassword}>
                <div>
                  <span className={styles.inputWrap}>{t("enterANewPassword")}</span>
                  <Form.Item name={'pw1'} className={styles.formItem}>
                    <Input.Password autoComplete='new-password'
                      onPressEnter={changePassword}
                      placeholder={t("enterPassword")}
                      className={stylesInput.input}
                      onChange={handleNewPassword}
                      value={newPassword}
                      suffix={<EyeTwoTone />}
                    />
                  </Form.Item>
                </div>
                <div>
                  <span className={styles.inputWrap}>{t("confirmThePassword")}</span>
                  <Form.Item
                    className={styles.formItem}
                    name={'pw2'}
                    rules={[
                      {
                        required: true,
                        message: 'Введите пароль еще раз.',
                        validateTrigger: 'onSubmit',
                      },
                      ({ getFieldValue }) => ({
                        validateTrigger: 'onSubmit',
                        validator(_, value) {
                          if (!value || getFieldValue('pw1') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Пароли не совпадают.'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password autoComplete='new-password'
                      placeholder={t("enterPassword")}
                      className={stylesInput.input}
                      value={repeatNewPassword}
                      onChange={handleRepeatNewPassword}
                      suffix={<EyeTwoTone />}
                      onPressEnter={changePassword}
                    />
                  </Form.Item>
                </div>
              </Form>
            </Modal>
            <div className={styles['close-btn']} onClick={() => goToLogin()} />
          </div>
        </Spin>
      </>
    </>
  );
};

export default connect((state: AppState) => {
  return {
    user: state.user,
  };
})(withRouter(Login));
