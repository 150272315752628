import React from 'react';
import Plot from 'react-plotly.js';

const PlaceholderChart: React.FC = () => {
  const data = [
    {
      x: [0, 3, 6, 10, 15, 20],
      y: [1, 1, 1, 1, 1, 1],
      mode: 'lines+markers',
      line: {
        color: '#D9D9D9',
        width: 2,
      },
      marker: {
        color: '#D9D9D9',
        size: 10,
        symbol: ['circle', 'square', 'triangle-left', 'x', 'triangle-right', 'diamond'],
      },
    },
    {
      x: [4, 7, 13, 14, 18, 25],
      y: [2, 2, 2, 2, 2, 2],
      mode: 'lines+markers',
      line: {
        color: '#D9D9D9',
        width: 2,
      },
      marker: {
        color: '#D9D9D9',
        size: 10,
        symbol: ['circle', 'square', 'triangle-left', 'x', 'triangle-right', 'diamond'],
      },
    },
    {
      x: [2, 5, 12, 14, 17, 22],
      y: [3, 3, 3, 3, 3, 3],
      mode: 'lines+markers',
      line: {
        color: '#D9D9D9',
        width: 2,
      },
      marker: {
        color: '#D9D9D9',
        size: 10,
        symbol: ['circle', 'square', 'triangle-left', 'x', 'triangle-right', 'diamond'],
      },
    },
    {
      x: [5, 8, 13, 17, 20, 21],
      y: [4, 4, 4, 4, 4, 4],
      mode: 'lines+markers',
      line: {
        color: '#D9D9D9',
        width: 2,
      },
      marker: {
        color: '#D9D9D9',
        size: 10,
        symbol: ['circle', 'square', 'triangle-left', 'x', 'triangle-right', 'diamond'],
      },
    },
  ];

  return (
    <Plot
      data={data}
      layout={{
        autosize: true,
        plot_bgcolor: '#E5ECF6',
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        height: 300,
        margin: {
          l: 0,
          r: 0,
          t: 0,
          b: 0,
        },
        xaxis: {
          showgrid: true,
          zeroline: false,
          showline: false,
          showticklabels: false,
          gridcolor: 'white',
        },
        yaxis: {
          showgrid: true,
          zeroline: false,
          showline: false,
          showticklabels: false,
          gridcolor: 'white',
          tickvals: [1, 2, 3, 4],
        },
        showlegend: false,
      }}
      style={{ width: '100%', height: '100%' }}
      config={{
        staticPlot: true,
      }}
    />
  );
};

export default PlaceholderChart;
