import React, { useEffect } from 'react';
import styles from './SectionContact.module.css';
import { CardElem, Contact, Landing } from '../../utils/types';
import EmailIcon from '../icons/EmailIcon';
import PhoneIcon from '../icons/PhoneIcon';
import LocationIcon from '../icons/LocationIcon';
import WebsiteIcon from '../icons/WebsiteIcon';

export interface SectionContactProps {
  block: Landing | Contact;
  cssClassWrap?: string;
  cssClassSupportContact?: string;
  cssClassSupportContactsWrap?: string;
}

const SectionContact: React.FC<SectionContactProps> = (props) => {
  const { block, cssClassWrap, cssClassSupportContact, cssClassSupportContactsWrap } = props;

  const iconsSupport: any = {
    mailIcon: <EmailIcon className={styles.supportIcon} />,
    phoneIcon: <PhoneIcon className={styles.supportIcon} />,
    addressIcon: <LocationIcon className={styles.supportIcon} />,
    websiteIcon: <WebsiteIcon className={styles.supportIcon} />,
  };

  return (
    <>
      <div className={cssClassWrap ? cssClassWrap : styles.support}>
        <div className={styles.supportTitle}>{block.label}</div>
        {block.info ? <div className={styles.supportSubtitle}>{block.info}</div> : null}
        <div
          className={
            cssClassSupportContactsWrap ? cssClassSupportContactsWrap : styles.supportContactsWrap
          }
        >
          {(block.data as CardElem[])?.map((item, i) => {
            return (
              <div
                className={cssClassSupportContact ? cssClassSupportContact : styles.supportContact}
                key={i}
              >
                {item.icon && iconsSupport[item.icon]}
                <div className={styles.supportContactInfo}>
                  <div>{item.info[0]}</div>
                  {item.title === 'mail' || item.title === 'website' ? (
                    <a
                      href={item.title === 'mail' ? `mailto:${item.info[1]}` : `${item.info[1]}`}
                      target={item.title === 'website' ? '_blank' : ''}
                      className={styles.link}
                      rel='noreferrer'
                    >
                      {item.info[1]}
                    </a>
                  ) : (
                    <div>{item.info[1]}</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SectionContact;
