import { Checkbox, CheckboxProps, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styles from './InputCheckbox.module.css';
import { BaseComponentProps } from 'interfaces/BaseComponentProps';
import { isArray } from 'lodash';
import EntityEditorContext, {
  EntityEditorContextData,
} from 'pages/entityEditor/EntityEditorContext/EntityEditorContext';
import modal from 'components/messages/modal';
import { connect, useDispatch } from 'react-redux';
import { ActionType } from 'store/actionTypes';
import { AppState } from 'store/reducers';
import HintIcon from '../SelectControl/HintIcon';
import { useWindowWidth } from '@react-hook/window-size';
import useModalWithText from 'hooks/useModalWithText';
import { getNewComponentsProps } from 'controllers/FormHandler';
import { useRefreshComponent } from 'hooks';
import { RefreshID } from 'enums/refresh';

export interface InputCheckboxProps extends BaseComponentProps {
  isTableDisabled: boolean;
}

const InputCheckbox: React.FC<InputCheckboxProps & CheckboxProps> = (props) => {
  const dispatch = useDispatch();
  const context = useContext<EntityEditorContextData>(EntityEditorContext);
  const valuePublicationWithDelay = context?.form?.getFieldValue('PublicationWithDelay');
  const width = useWindowWidth();
  const { dataModal, getDataModal } = useModalWithText();
  const {refreshComponent} = useRefreshComponent();

  const { ownProps, ...checkboxProps } = props;
  const { hint, label, disableCheckbox, disableCheckboxInboxName, disableCheckboxRelatedField } =
    ownProps.component || {};

  const disabled: boolean = !!ownProps.component?.readonly;

  const tooltipEl =
    (disabled && !ownProps.component?.params?.forceShowHint) || !hint ? (
      <></>
    ) : (
      <div>
        <HintIcon hint={hint} cssClassTooltip={styles.tooltip} />
      </div>
    );

  const disableTable = () => {
    if (context.factory && disableCheckbox && props.isTableDisabled === false) {
      const propName: string = ownProps.component?.propName || '';
      const formValues: any = context?.form?.getFieldsValue(true);
      const formValue: any = formValues?.[propName];
      if (formValue === true) {
        dispatch({
          type: ActionType.UPDATE_EDITOR_DISABLED_TABLE_CONTROLS,
          payload: {
            inboxName: disableCheckboxInboxName,
            value: true,
          },
        });
      }
    }
  };

  useEffect(() => {
    disableTable();
  }, [context, context.form, ownProps, context?.form?.getFieldsValue(true)]);

  useEffect(() => {
    if (valuePublicationWithDelay !== undefined) {
      dispatch({
        type: ActionType.UPDATE_EDITOR_DISABLED_DATEPICKER,
        payload: {
          value: !valuePublicationWithDelay,
          field: disableCheckboxRelatedField,
        },
      });
      if (!valuePublicationWithDelay) {
        context?.form?.setFieldsValue({ DateOfPublication: undefined });
      }
    }
  }, [valuePublicationWithDelay]);

  useEffect(() => {
    return (() => {
      dispatch({
        type: ActionType.UPDATE_EDITOR_DISABLED_TABLE_CONTROLS,
        payload: {
          inboxName: disableCheckboxInboxName,
          value: false,
        },
      });
    })
  }, [])

  useEffect(() => {
    if (!!ownProps.component?.propName && ownProps.component.jsonConfig.disableCheckbox && !ownProps.component.visible) {
      getDataModal(ownProps.component?.propName, width)
    }
  }, [ownProps.component?.propName])

  return (
    <div className={styles.wrap}>
      <Checkbox
        className={styles.checkbox}
        disabled={disabled}
        {...{
          ...checkboxProps,
          ...{
            onChange: (...args) => {
              checkboxProps.onChange?.apply(this, args);
              if (ownProps.component?.renderer?.includes('WithListener') || ownProps.component?.templateName?.includes('WithListener')) {
                const newComponents = getNewComponentsProps('/OnChangeField/changeLayout', {propName: ownProps.component!.propName!, value: args[0].target.checked, object: {classType: context.factory!.classType!, id: context.factory?.layoutConfig.id}});
                newComponents.then(components => {
                  components.forEach(component => {
                    context.factory?.modifyComponent(component.propName, {...component});
                  })
                  refreshComponent(RefreshID.EDITOR_DETAILS);
                })
              }
              // IRK-213
              if (disableCheckbox && args && isArray(args) && args.length === 1) {
                const event: any = args[0].target;
                if (event) {
                  const propName: string = ownProps.component?.propName || '';
                  const relatedField: string = disableCheckboxRelatedField || '_UNDEFINED_';
                  const relatedCheckboxes: any[] = (
                    context.factory?.disabledCheckboxes[relatedField + disableCheckboxInboxName] ||
                    []
                  ).filter((rc: string) => {
                    return rc !== propName;
                  });
                  const formValues: any = context?.form?.getFieldsValue(true);
                  const formValue: any = formValues[relatedField];
                  if (event.checked) {
                    const uncheckRelated = () => {
                      // uncheck all related checkboxes, radio button style
                      const unchecked: any = {};
                      relatedCheckboxes.forEach((rel: string) => {
                        if (formValues[rel]) {
                          unchecked[rel] = false;
                        }
                      });
                      if (Object.keys(unchecked).length > 0) {
                        context.form?.setFieldsValue(unchecked);
                      }
                    };
                    if (formValue && formValue.length > 0) {
                      modal.showModal({
                        onOk: () => {
                          dispatch({
                            type: ActionType.UPDATE_EDITOR_DISABLED_TABLE_CONTROLS,
                            payload: {
                              inboxName: disableCheckboxInboxName,
                              value: true,
                            },
                          });
                          context.form?.setFieldsValue({ [relatedField]: null });
                          uncheckRelated();
                        },
                        onCancel: () => {
                          context.form?.setFieldsValue({
                            [propName]: false,
                          });
                          dispatch({
                            type: ActionType.UPDATE_EDITOR_DISABLED_TABLE_CONTROLS,
                            payload: {
                              inboxName: disableCheckboxInboxName,
                              value: false,
                            },
                          });
                        },
                        ...dataModal
                      });
                    } else {
                      dispatch({
                        type: ActionType.UPDATE_EDITOR_DISABLED_TABLE_CONTROLS,
                        payload: {
                          inboxName: disableCheckboxInboxName,
                          value: true,
                        },
                      });
                      uncheckRelated();
                    }
                  } else {
                    // check if all related checkboxes unchecked
                    const value: boolean = relatedCheckboxes.reduce(
                      (result: boolean, rc: string) => {
                        if (formValues[rc] === true) {
                          result = true;
                        }
                        return result;
                      },
                      false
                    );
                    dispatch({
                      type: ActionType.UPDATE_EDITOR_DISABLED_TABLE_CONTROLS,
                      payload: {
                        inboxName: disableCheckboxInboxName,
                        value,
                      },
                    });
                  }
                }
              }
              // EOF IRK-213
            },
          },
        }}
      >
        <span className={styles.label}>{label}</span>
      </Checkbox>
      {tooltipEl}
    </div>
  );
};

export default connect((state: AppState, props: any) => {
  const { ownProps } = props;
  const { disableCheckboxInboxName } = ownProps.component || {};
  const isTableDisabled: boolean =
    state.editor.disabledTableControls[disableCheckboxInboxName] === true;
  return {
    isTableDisabled,
  };
})(React.memo(InputCheckbox));
