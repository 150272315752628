import React, { FC, ReactElement, useEffect, useState } from 'react';
import { WidgetItem } from 'utils/types';
import s from './widgelist.module.css';
import { Dropdown, Space } from 'antd';
import EyeInvisibleIcon from 'components/icons/EyeInvisibleIcon';
import { useWindowWidth } from '@react-hook/window-size';
import EyeBurgerIcon from 'components/icons/EyeBurgerIcon';
import axios from 'axios';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import notification from 'components/messages/notification';

export type WidgetListProps = {
  data: WidgetItem[];
};

export const WidgetList: FC<WidgetListProps> = ({ data }) => {
  const [selectedButtons, setSelectedButtons] = useState<number[]>([]);
  const [widgetsList, setWidgetsList] = useState<ReactElement<'div'>[]>();

  const width = useWindowWidth();

  const layoutWidth = document.querySelector('.ant-layout-content');

  const handleVisibility = (widget: WidgetItem) => {
    const body: FormData = new FormData();
    body.append('choiceCode', `${widget.choiceCode}`);
    body.append('isVisible', `${!widget.isVisible}`);
    widget.rowSpan && body.append('rowSpan', `${widget.rowSpan}`);
    widget.col && body.append('col', `${widget.col}`);
    widget.colSpan && body.append('colSpan', `${widget.colSpan}`);
    widget.row && body.append('row', `${widget.row}`);
    widget.order && body.append('order', `${widget.order}`);
    axios.post('Landing/updateWidgetByUser', body).then((response: AxiosResponseExt) => {
      if (response.error) {
        notification.error({
          text: response.error,
          width,
        });
      } else {
        selectedButtons?.includes(widget.choiceCode)
          ? setSelectedButtons((prev) => prev?.filter((title) => title !== widget.choiceCode))
          : setSelectedButtons((prev) => [...prev, widget.choiceCode]);
      }
    });
  };

  const items = data?.map((item) => ({
    key: item.choiceCode,
    title: item.title,
    label: item.title,
    isVisible: item.isVisible,
    icon: selectedButtons?.includes(item.choiceCode) ? <EyeBurgerIcon /> : <EyeInvisibleIcon />,
    onClick: () => handleVisibility(item),
    className: s.activeIcon,
  }));

  useEffect(() => {
    if (!!data) {
      setSelectedButtons(data?.filter((item) => !!item.choiceCode && !!item.isVisible).map((item) => item.choiceCode));
    }
  }, [data]);

  useEffect(() => {
    if (data && layoutWidth)
      setWidgetsList(
        data
          .sort((a, b) => {
            if (a === null || a.order === null) return 1;
            if (b === null || b.order === null) return -1;
            return a.order - b.order;
          })
          .map((item) => (
            <div
              className={s.widgetItem}
              style={{
                height: 450,
                width:
                  layoutWidth.clientWidth >= 1024
                    ? item.colSpan * (layoutWidth?.clientWidth / 12 - 15) + 'px'
                    : 12 * (layoutWidth?.clientWidth / 12 - 3) + 'px',
                display: selectedButtons.includes(Number(item.choiceCode)) ? 'block' : 'none',
                overflow: 'hidden',
              }}
              key={item.choiceCode}
            >
              <iframe
                src={item.link}
                title={item.title}
                width={
                  layoutWidth.clientWidth >= 1024
                    ? item.colSpan * (layoutWidth?.clientWidth / 12 - 15) + 'px'
                    : 12 * (layoutWidth?.clientWidth / 12 - 3) + 'px'
                }
                height={450}
                className={s.widgetIframe}
                tabIndex={-1}
              ></iframe>
            </div>
          ))
      );
  }, [selectedButtons, data, layoutWidth]);

  return (
    <div className={s.container}>
      <div className={s.burgerMenu}>
        <Dropdown menu={{ items }} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <svg
                viewBox='64 64 896 896'
                focusable='false'
                data-icon='menu'
                width='50px'
                height='35px'
                fill='var(--color-primary)'
                aria-hidden='true'
              >
                <path d='M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z'></path>
              </svg>
            </Space>
          </a>
        </Dropdown>
      </div>
      <div className={s.widgetsContainer}>{widgetsList}</div>
    </div>
  );
};
