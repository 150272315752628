import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from 'store/reducers';
import { connect } from 'react-redux';
import Form from 'antd/lib/form/Form';
import TwoColumnGrid from 'components/layout/content/TwoColumnGrid';
import SiderWithTabs from 'components/navigation/Sider/SiderWithTabs';
import { useTranslation } from 'react-i18next';
import InboxImagePage from './InboxImagePage';
import InboxOrgPage from './InboxOrgPage';
import InboxGroupList from './InboxGroupList';
import InboxBlocksPage from './InboxBlocksPage';

export interface InboxGroupProps {
  menu: any;
}

export interface InboxGroupParams {
  groupID?: string;
}

const isImagePage = (inbox: string) =>
  ['aviaExploit'].includes(inbox);

const isOrgPage = (inbox: string) =>
  ['mto', 'pap'].includes(inbox);

const isBlocksPage = (inbox: string) =>
  ['ServiceСatalogPPOForAll'].includes(inbox);

const InboxGroupPage: React.FC<InboxGroupProps & RouteComponentProps> = (props) => {
  const menu = props.menu;
  const routerParams: InboxGroupParams = props.match.params;
  const groupID = routerParams.groupID;
  const curInboxGroup = menu.inboxGroups.find((el: any) => el.inboxGroup.id === groupID);

  const {t} = useTranslation();

  if (!curInboxGroup?.inboxGroup?.inboxes) {
    return null;
  }

  if (menu.inboxGroups.length === 0) {
    return <span>{t("loading")}</span>;
  }

  const mainPageInbox = curInboxGroup.mainPageInboxes && curInboxGroup.mainPageInboxes[0];

  return (
    <TwoColumnGrid
      buttonState={true}
      inboxGroup={groupID}
      className='main-content__wrap'
      sider={<SiderWithTabs tabs={undefined} filters={undefined} />}
    >
      <Form className={['app-content', 'main-content__wrap'].join(' ')}>
        {isImagePage(mainPageInbox) ?
          <InboxImagePage inbox={mainPageInbox} groupID={groupID}/> :
          isOrgPage(mainPageInbox) ?
          <InboxOrgPage inbox={mainPageInbox} groupID={groupID} pageTitle={curInboxGroup?.inboxGroup?.label}/> :
          isBlocksPage(mainPageInbox) ?
          <InboxBlocksPage inboxGroup={curInboxGroup}/> :
          <InboxGroupList
            inboxGroup={curInboxGroup}
            groupID={groupID}
            entityListProps={props}
            menu={menu}
          />}
      </Form>
    </TwoColumnGrid>
  );
}

export default connect((state: AppState) => {
  return {
    menu: state.menu,
  };
})(InboxGroupPage);