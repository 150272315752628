export enum DataType {
  DATE = 'DATE',
  TEXT = 'TEXT',
  LONG = 'LONG',
  GUID = 'GUID',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  OBJECT_STATE = 'OBJECT_STATE',
  DOUBLE = 'DOUBLE',
}

// export enum AttributeTemplateType {
//   SERVICE_REQUEST = 'ServiceRequest',
//   DIFF_ELABORATION_FH = 'DifferenceElaborationFH',

// }

export enum ControlType {
  TEXT = 'text',
  INPUT = 'input',
  FILES_LIST = 'files_list',
  SELECT = 'select',
  DATETIME = 'DATETIME',
  DATE = 'DATE',
  DATE_RANGE = 'date-range',
  TEXTAREA = 'textarea',
  HREF = 'href',
  CURRENCY = 'current',
  AUTOSUGGEST = 'autosuggest',
  CHECKBOX = 'checkbox',
  SWITCH = 'switch',
  TABLE_DATA = 'dataTable',
  CARD_TABLE = 'cardTable',
  TECH_SUP_BLOCK = 'techSupportBlock',
  EMAIL_NOTIFICATIONS = 'emailNotifications',
  TIME_INPUT = 'timeInput',
  CHART = 'chart',
  UNKNOW = '_unknown_',
}

export interface BaseComponentInterface {
  fetchParamMultiple?: boolean | undefined;
  renderer?: string | null; // "multiselect | dynamicMultiselect"
  virtual?: boolean;
  hidden?: boolean | null;
  isUnique?: boolean;
  DateCreated?: string;
  DateLastModified?: string;
  required?: boolean | null;

  jsonConfig?: any;

  masked?: boolean;

  ObjectState?: string; // ??? values
  value?: string | null;
  defaultValueRaw?: any; // ??? value
  readonly?: boolean | null;
  scope?: null; // ??? value
  ClassName?: string; // ??? value: class_description, property_description
  ID: string;
  Files: any[]; // ??? values
  measurable?: any; // ??? value
  LastModifier: string;
  ToDelete?: boolean;
  dataType: DataType;
  multiple: boolean | null;
  Title: string;
  measureUnit?: null; // ??? value
  sortable: boolean | null;
  attributeTemplate: string | null; // ??? values - to enum?
  parentName: string | null; // ??? values - to enum?
  system: boolean;
  classificationGroupsMap: null; // ??? values
  hint?: string | null;
  symbolicName: string;
  choiceList: string | null;
  Author: string;
  classificationGroupAttribute: string | null;
  maxLength: number | null;
  parameters?: any;
  propertyType?: any;
}
