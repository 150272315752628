import React from 'react';
import { Dispatch } from 'redux';
import { Col, FormInstance } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import axios, { AxiosResponse } from 'axios';
import { isNull, isUndefined, cloneDeep } from 'lodash';
import { stringify } from 'qs';
import modal from 'components/messages/modal';
import InputCheckbox from 'components/controls/Checkbox/InputCheckbox';
import FilesList from 'components/controls/FilesList/FilesList';
import InputAutosuggest from 'components/controls/InputAutosuggest/InputAutosuggest';
import InputDataRange from 'components/controls/InputDate/InputDataRange';
import InputDate from 'components/controls/InputDate/InputDate';
import InputText from 'components/controls/InputText/InputText';
import SelectControl, {
  getLinkedSelectProp,
} from 'components/controls/SelectControl/SelectControl';
import SwitchControl from 'components/controls/SwitchControl/SwitchControl';
import CardTable from 'components/controls/CardTable';
import InputTextArea from 'components/controls/TextArea/InputTextArea';

import { isHiddenComponent } from 'components/CardLayout/utils';
import { AxiosResponseExt } from 'interfaces/AxiosResponseExt';
import { BaseComponentInterface, ControlType, DataType } from 'interfaces/BaseComponentInterface';
import { FormComponentMeta } from 'interfaces/BaseComponentProps';
import { ActionType } from 'store/actionTypes';
import CacheStorage from './CacheStorage';
import MiscUtils from './MiscUtils';
import { JsObject, JsObjectNested } from 'interfaces/Object';
import notification from '../components/messages/notification';
import TechSupBlock from 'components/controls/TechSupBlock/TechSupBlock';
import EmailNotifications from 'components/controls/EmailNotifications/EmailNotifications';
import TimeInput from 'components/controls/TimeInput/TimeInput';
import i18n from 'i18n';
import Chart from 'components/controls/Chart/Chart';

export interface CompomentCreateOptions {
  component: FormComponentMeta & BaseComponentInterface;
  additionProps?: any;
}

export interface FilterComponent {
  component?: any;
  propName?: string;
  label?: string;
  type?: ControlType;
  isHidden: boolean;
  isCustom: boolean;
  jsonConfig?: any;
  className?: string;
}

export interface LinkedChoiceRequestParams {
  classType?: string;
  value: string;
  propName?: string;
  choiceName: string;
}

export type ControlMap = {
  [key in ControlType]?: React.FC<any>;
};

// export const ControlFactoryVirtualChoice:string = '@virtual@_'

class ControlFactory {
  classType?: string;
  dispatch?: Dispatch;
  layoutConfig: any;
  choiceListData: any = {};
  choiceListDataToFetch: any = {};
  choiceListDataWithParamsToFetch: any = {};
  choiceListDataDeps: any = {};
  choiceListDataPending: any = {};
  tableDataToFetch: any = {};
  disabledCheckboxes: any = {};
  componentMap: any = {};
  _components: FilterComponent[] = [];

  // controlMap должна находиться внутри ControlFactory
  // если вынести за пределы ControlFactory появится ошибка вебпака
  controlMap: ControlMap = Object.freeze({
    [ControlType.TEXTAREA]: InputTextArea,
    [ControlType.INPUT]: InputText,
    [ControlType.CHECKBOX]: InputCheckbox,
    [ControlType.DATE_RANGE]: InputDataRange,
    [ControlType.DATETIME]: InputDate,
    [ControlType.DATE]: InputDate,
    [ControlType.SELECT]: SelectControl,
    [ControlType.SWITCH]: SwitchControl,
    [ControlType.FILES_LIST]: FilesList,
    [ControlType.CARD_TABLE]: CardTable,
    [ControlType.AUTOSUGGEST]: InputAutosuggest,
    [ControlType.TECH_SUP_BLOCK]: TechSupBlock,
    [ControlType.EMAIL_NOTIFICATIONS]: EmailNotifications,
    [ControlType.TIME_INPUT]: TimeInput,
    [ControlType.CHART]: Chart
  });

  constructor(dispatch?: Dispatch) {
    this.dispatch = dispatch;
  }

  updateDispatch(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  clone(dispatch?: Dispatch) {
    const newFactory = new ControlFactory(dispatch || this.dispatch);

    Object.keys(this).forEach((key: string) => {
      if (key === 'dispatch') return;
      newFactory[key as keyof ControlFactory] = this[key as keyof ControlFactory];
    });

    return newFactory;
  }

  setClassType(classType: string) {
    this.classType = classType;
  }

  async setConfig(config: any, meta?: any) {
    this.layoutConfig = this._prepareLayoutConfig(config, meta);
    await this._fetchTableData();
    this._fetchChoiceListData();
  }

  getConfig() {
    return this.layoutConfig;
  }

  getPanels() {
    return this.layoutConfig && this.layoutConfig.panels;
  }

  getChoiceListData() {
    return this.choiceListData;
  }

  /* case for filters */
  addComponent(options: any) {
    this._processJsonConfig(options.component);
    this.updateMultipleForSelect(options.component);
    const type: ControlType = this._getControlType(options.component);

    const component = this.createControlElement(type, options);
    this._components.push({
      component,
      propName: options.component.propName,
      label: options.component.label
        ? options.component.label
        : `__${options.component.propName}__`,
      isHidden: this.isHidden(options.component),
      isCustom: this.isCustom(options.component),
      jsonConfig: options.component.jsonConfig,
      type,
    });
    this._prepareChoiceListDataToFetch(options.component);
    this._updateDisabledCheckboxes(options.component);
  }

  getComponents() {
    return this._components;
  }

  /* EOF case for filters */

  _updateDisabledCheckboxes(component: any) {
    if (component && component.disableCheckbox === true) {
      const disabledField: string = component.disableCheckboxRelatedField || '';
      const disabledInboxName: string = component.disableCheckboxInboxName || '';
      if (!this.disabledCheckboxes[disabledField + disabledInboxName]) {
        this.disabledCheckboxes[disabledField + disabledInboxName] = [];
      }
      this.disabledCheckboxes[disabledField + disabledInboxName].push(component.propName);
    }
  }

  _processJsonConfig(component: any) {
    let jsonConfig: any = {};
    if (component.jsonConfig) {
      try {
        jsonConfig = JSON.parse(component.jsonConfig);
      } catch (e) {
        /* no-op */
      }
      component.jsonConfig = jsonConfig;
    }
    component.jsonConfig = jsonConfig;
    if (component.jsonConfig.disableCheckbox) {
      component.disableCheckbox = component.jsonConfig.disableCheckbox === 'true' ? true : false;
      component.disableCheckboxRelatedField = component.jsonConfig.disableCheckboxRelatedField;
      component.disableCheckboxInboxName = component.jsonConfig.disableCheckboxInboxName;
    }
    return jsonConfig;
  }

  _prepareLayoutConfig(config: any, meta?: any) {
    if (!config || !config.panels) {
      return config;
    }
    const panels: any[] = [];
    config.panels.forEach((panel: any, i: number) => {
      let allComponentsIsHidden = true;
      panel.componentsRows.forEach((row: any, r: number) => {
        const componentToDelete: string[] = [];
        row.components.forEach((com: any, c: number) => {
          let component: any;
          const metaInfo: any | undefined = meta && meta[com.propName];
          if (metaInfo !== undefined) {
            component = metaInfo;
          }
          component = { ...component, ...com };
          // parse json config
          this._processJsonConfig(component);
          if (!component.dataType && component.propertyType) {
            component.dataType = component.propertyType;
          }
          const isHidden: boolean = this.isHidden(component);
          component.hidden = isHidden;

          if (allComponentsIsHidden && !isHidden) allComponentsIsHidden = false;
          // if (isHidden) {
          //   componentToDelete.push(component.propName);
          // } else {
          panel.componentsRows[r].components[c] = this._prepareChoiceListDataToFetch(component);
          this.componentMap[component.propName] = component;
          this._updateDisabledCheckboxes(component);
          // }
        });
        // filter hidden component
        // if (componentToDelete.length > 0) {
        //   panel.componentsRows[r].components = panel.componentsRows[r].components.filter(
        //     (_component: any) => {
        //       return componentToDelete.indexOf(_component.propName) === -1;
        //     }
        //   );
        // }
      });
      panel.hidden = panel.hidden || allComponentsIsHidden;

      // remove empty rows
      const newRows: any = [];
      panel.componentsRows.forEach((row: any, r: number) => {
        if (row.components.length > 0) {
          newRows.push(row);
        }
      });
      panel.componentsRows = newRows;
      if (panel.componentsRows.length > 0) {
        panels.push(panel);
      }
    });
    config.panels = panels;
    return config;
  }

  updatePanelHidden(panel: any) {
    if (!panel) return;
    let allComponentsIsHidden = true;

    panel.componentsRows.forEach((row: any, r: number) => {
      row.components.forEach((com: any, c: number) => {
        if (allComponentsIsHidden && !this.isHidden(com)) allComponentsIsHidden = false;
      });
    });

    panel.hidden = allComponentsIsHidden;
    return panel;
  }

  _isChoiceListToFetch(type: ControlType) {
    return type === ControlType.SELECT || type === ControlType.SWITCH;
  }

  _prepareChoiceListDataToFetch(component: any) {
    const type: ControlType = this._getControlType(component);
    if (this._isChoiceListToFetch(type)) {
      const jsonConfig: any = component.jsonConfig;
      // Virtual choice list skipping dependancies
      if (component.virtual) {
        return component;
      }
      this.updateMultipleForSelect(component);
      if (jsonConfig && jsonConfig.parametrized) {
        // calculate dependent fields
        const dependentFields: any = {};
        jsonConfig.parametrized.required.forEach((reqParam: any) => {
          dependentFields[jsonConfig.parametrized.parameters[reqParam]] = true;
        });
        // save params needed for fetching data
        this.choiceListDataWithParamsToFetch[component.choiceName] = {
          ...jsonConfig.parametrized,
          ...{
            choiceName: component.choiceName,
            propName: component.propName,
            multiple: component.multiple,
            jsonConfig: component.jsonConfig,
            fetchParamMultiple: component.fetchParamMultiple,
          },
          dependentFields,
        };
        // populate dependencies map
        jsonConfig.parametrized.required.forEach((reqParam: any) => {
          if (!this.choiceListDataDeps[reqParam]) {
            this.choiceListDataDeps[reqParam] = {};
          }
          this.choiceListDataDeps[reqParam][component.propName] = {
            multiple: component.multiple,
            component, // for test
          };
        });
      } else {
        this.choiceListDataToFetch[component.choiceName] = {
          choiceName: component.choiceName,
          component,
        };
      }
    } else if (type === ControlType.TABLE_DATA) {
      this.tableDataToFetch[component.params.inboxName] = {
        inboxName: component.params.inboxName,
      };
    }
    return component;
  }

  isFetchValue(formValues: JsObject) {
    let isFetchValue: boolean = false;
    Object.keys(formValues).forEach((key: string) => {
      Object.keys(this.choiceListDataWithParamsToFetch).forEach((fetchKey: string) => {
        const dependencies: any = this.choiceListDataWithParamsToFetch[fetchKey];
        if (key in dependencies.dependentFields) {
          isFetchValue = true;
          return true;
        }
      });
    });
    return isFetchValue;
  }

  __resetDependFormValues(key: string, form: any) {
    const deps: any = this.choiceListDataDeps[key];
    const keys: string[] = deps ? Object.keys(this.choiceListDataDeps[key]) : [];
    keys.forEach((depKey: string) => {
      form.setFieldsValue({
        [depKey]: this.choiceListDataDeps[key][depKey].multiple ? [] : '',
      });
      if (this.choiceListDataDeps[depKey]) {
        this.__resetDependFormValues(depKey, form);
      }
    });
  }

  handleComponentChange(formValues: JsObject, form?: FormInstance, object?: any) {
    const fetchList: any = {};
    Object.keys(formValues).forEach((key: string) => {
      Object.keys(this.choiceListDataWithParamsToFetch).forEach((fetchKey: string) => {
        const dependencies: any = this.choiceListDataWithParamsToFetch[fetchKey];
        if (key in dependencies.dependentFields) {
          fetchList[fetchKey] = dependencies;
          // drop value on related fields and linked
          if (form && dependencies.propName) {
            if (dependencies.relatedField && form.getFieldValue(dependencies.relatedField)?.length > 0 && object) {
              // modal.disabledCheckbox({
              //   onOk: () => {
              //     this.__resetDependFormValues(key, form);
              //   },
              //   onCancel: () => {
              //     form.setFieldsValue({
              //       [key]: object.params[key],
              //     });
              //   },
              // });
            } else {
              this.__resetDependFormValues(key, form);
              form.setFieldsValue({
                [dependencies.propName]: dependencies.multiple ? [] : '',
              });
            }
            const linkedSelectProp: string | undefined = getLinkedSelectProp(dependencies);
            if (linkedSelectProp) {
              form.setFieldsValue({
                [linkedSelectProp]: '',
              });
            }

            if (dependencies.jsonConfig?.linked) {
              Object.keys(dependencies.jsonConfig.linked).forEach((linkedPropName: string) => {
                if (linkedPropName) {
                  form.setFieldsValue({
                    [linkedPropName]: undefined,
                  });
                }
              });
            }
          }
        }
      });
    });
    if (Object.keys(fetchList).length > 0) {
      this.___fetchChoiceListDataWithParams(
        form ? form.getFieldsValue(true) : formValues,
        fetchList
      );
    }
  }

  ___fetchChoiceListDataWithParams(formValues: any, fetchList: any) {
    return new Promise((resolve) => {
      const requests: any[] = [];
      const orderedKyes: string[] = [];
      const missingKeys: any = {};
      Object.keys(fetchList).forEach((key: string) => {
        const choice: any = fetchList[key];
        const params: any = {};
        choice.required.forEach((reqParam: string) => {
          let formValue: any = formValues[choice.parameters[reqParam]];
          // if (formValue != null) {
          // IRK-1010
          if (choice.fetchParamMultiple && !Array.isArray(formValue)) {
            formValue = [formValue];
          }
          params[reqParam] = formValue;
          // }
        });
        if (choice.required.length !== Object.keys(params).length) {
          // throw new Error(`Missing required parameters`);
          /* tslint:disable */
          console.warn(`Missing required parameters`);
          missingKeys[choice.choiceName] = {};
        } else {
          orderedKyes.push(choice.choiceName);
          requests.push(
            axios.get(
              encodeURI(
                `/SPChoice/getChoiceItems?choiceName=${choice.choiceName
                }&parameters=${JSON.stringify(params)}`
              )
            )
          );
        }
      });
      if (Object.keys(missingKeys).length > 0 && this.dispatch) {
        this.dispatch({
          type: ActionType.UPDATE_CHOICE_LISTS,
          payload: missingKeys,
        });
        // debugger;
        /* tslint:disable */
      }
      Promise.all(requests)
        .then((responses: AxiosResponse[]) => {
          const choiceListData: any = {};
          responses.forEach((response: AxiosResponse, idx: number) => {
            choiceListData[orderedKyes[idx]] = { choiceItems: response.data };
          });
          if (this.dispatch) {
            this.dispatch({
              type: ActionType.UPDATE_CHOICE_LISTS,
              payload: choiceListData,
            });
          }
          resolve(Promise.resolve(true));
        })
        .catch(() => {
          resolve(Promise.resolve(true));
        });
    });
  }

  _fetchChoiceListDataWithParams(form: any, data?: any) {
    if (!this.dispatch) {
      return Promise.resolve(true);
    }
    let formValues: any = form.getFieldsValue(true);
    if (data) {
      formValues = { ...formValues, ...data };
    }
    return this.___fetchChoiceListDataWithParams(formValues, this.choiceListDataWithParamsToFetch);
  }

  fetchLinkedChoiceData(params: LinkedChoiceRequestParams) {
    return new Promise((resolve, reject) => {
      if (!params.classType) {
        reject(`Missing classType`);
      }
      axios
        .get(`/link/linkChoiceTemplate`, { params })
        .then((response: AxiosResponseExt) => {
          if (!response.error) {
            resolve(response.data);
          } else {
            resolve([]);
          }
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  _fetchChoiceListData() {
    return new Promise(async (resolve) => {
      if (!this.dispatch) {
        return resolve({});
      }
      const keysFromCache: any = {};
      const keysFromServer: string[] = [];
      const keys: Promise<any>[] = [];
      const keysNames: string[] = [];

      Object.keys(this.choiceListDataToFetch).forEach((key: string) => {
        const filtredKey: boolean = !MiscUtils.isBlankString(key) && key !== 'null';
        if (filtredKey) {
          // add to pending
          CacheStorage.setPending(key);
          keys.push(CacheStorage.get(key));
          keysNames.push(key);
        }
      });
      const getChoiceList = async (params: any, keysFromCache: any, removeFromPending: any) => {
        await axios
          .get(`/SPChoice/list?choiceNames=${stringify(params, { delimiter: ',' })}`)
          .then((response: any) => {
            if (!response.data) {
              notification.error({
                text: response.error,
                screenWidth: window.innerWidth,
              });
            }
            if (this.dispatch) {
              if (Object.keys(response.data).length === 0) {
                this.dispatch({
                  type: ActionType.UPDATE_CHOICE_LISTS,
                  payload: keysFromCache,
                });
              } else {
                Object.keys(response.data).forEach((key: string) => {
                  delete this.choiceListDataToFetch[key];
                  delete this.choiceListDataPending[key];
                  choiceListData[key] = response.data[key];
                  CacheStorage.set(key, response.data[key]);
                  CacheStorage.removePending(key);
                });

                this.dispatch({
                  type: ActionType.UPDATE_CHOICE_LISTS,
                  payload: choiceListData,
                });
              }
            }
          })
          .catch(() => {
            if (removeFromPending !== null) {
              // removeFromPending();
            }
            resolve({});
          });
      };

      const filtredKeys: any = await Promise.all(keys);
      filtredKeys.forEach((fk: any, fki: number) => {
        if (isNull(fk) || isUndefined(fk)) {
          keysFromServer.push(keysNames[fki]);
        } else {
          keysFromCache[keysNames[fki]] = fk;
        }
      });
      // push data to redux
      const choiceListData: any = {};

      if (Object.keys(keysFromCache).length > 0) {
        const copyKeysFromCache = cloneDeep(keysFromCache);
        Object.keys(copyKeysFromCache).forEach((key: string) => {
          copyKeysFromCache[key] = copyKeysFromCache[key].Version;
        });
        await getChoiceList(copyKeysFromCache, keysFromCache, null);
      }

      // fetch data from server
      if (keysFromServer.length > 0) {
        const removeFromPending = () => {
          keysFromServer.forEach((pendingKey: string) => {
            CacheStorage.removePending(pendingKey);
          });
        };
        const data: any = {};
        keysFromServer.forEach((key) => {
          data[key] = 'null';
        });
        await getChoiceList(data, {}, removeFromPending);
      }
      resolve({});
    });
  }

  async _fetchTableData() {
    const keys: string[] = Object.keys(this.tableDataToFetch);
    if (keys.length > 0) {
      const promises: any[] = keys.map((key) => {
        return new Promise((resolve) => {
          if (!this.dispatch) {
            return resolve({});
          }
          axios
            .get(`/GetDataTable?inboxName=${key}`)
            .then((response: AxiosResponse) => {
              delete this.tableDataToFetch[key];
              resolve({
                inboxName: key,
                data: response.data,
              });
            })
            .catch(() => {
              resolve({});
            });
        });
      });
      return Promise.all(promises).then((res) => {
        const result: any = {};
        const resultState: any = {};
        res.forEach((resultTable) => {
          resultState[resultTable.inboxName] = {
            visible: false,
          };
          result[resultTable.inboxName] = resultTable.data;
          resultTable.data.fields?.forEach((field: any) => {
            if (field.choiceName) {
              const type: ControlType = this._getControlType(field);
              if (this._isChoiceListToFetch(type)) {
                this.choiceListDataToFetch[field.choiceName] = {
                  choiceName: field.choiceName,
                  component: field,
                };
              }
            }
          });
        });
        if (this.dispatch) {
          this.dispatch({
            type: ActionType.UPDATE_TABLE_DATA,
            payload: result,
          });
        }
      });
    }
  }

  _getControlType = (meta?: any) => {
    if (!meta) {
      return ControlType.UNKNOW;
    }
    if (meta.params?.viewType === 'switch') {
      return ControlType.SWITCH;
    }

    if (meta.templateName != null) {
      switch (meta.templateName) {
        case 'dataTable':
          return ControlType.TABLE_DATA;
        case 'cardTable':
          return ControlType.CARD_TABLE;
        case 'text':
          return ControlType.TEXT;
        case 'input':
        case 'currency':
        case 'numeric':
        case 'risk':
          return ControlType.INPUT;
        case 'textarea':
          return ControlType.TEXTAREA;
        case 'select':
        case 'dynamicSelect':
        case 'dynamicMultiselect':
        case 'multiselect':
        case 'autocomplete':
        case 'selectWithListener':
          return ControlType.SELECT;
        case 'checkbox':
        case 'checkboxWithListener':
          return ControlType.CHECKBOX;
        case 'attachmentList':
          return ControlType.FILES_LIST;
        case 'datePicker':
          return ControlType.DATE;
        case 'dateTimePicker':
          return ControlType.DATETIME;
        case 'loadData':
        case 'loadMultiData':
          return ControlType.AUTOSUGGEST;
        case 'techSupportBlock':
          return ControlType.TECH_SUP_BLOCK;
        case 'emailNotifications':
          return ControlType.EMAIL_NOTIFICATIONS;
        case 'timeInput':
          return ControlType.TIME_INPUT;
        case 'chart':
          return ControlType.CHART;
        case 'betweenDatePicker':
          return ControlType.DATE_RANGE;
      }
    }
    // special case for filters
    if (
      meta.jsonConfig &&
      meta.jsonConfig.customFilter &&
      meta.jsonConfig.renderer === 'checkbox'
    ) {
      return ControlType.CHECKBOX;
    }
    if (meta.renderer !== null) {
      switch (meta.renderer) {
        case 'checkbox':
        case 'checkboxWithListener':
          return ControlType.CHECKBOX;
        case 'input':
          if (meta.dataType === DataType.GUID) {
            return ControlType.FILES_LIST;
          }
          return ControlType.INPUT;
        case 'datePicker':
          return ControlType.DATE;
        case 'betweenDatePicker':
          return ControlType.DATE_RANGE;
        case 'dateTimePicker':
          return ControlType.DATETIME;
        case 'textarea':
          return ControlType.TEXTAREA;
        case 'href':
          return ControlType.HREF;
        case 'select':
        case 'dynamicSelect':
        case 'multiselect':
        case 'autocomplete':
        case 'selectWithListener':
            return ControlType.SELECT;
        case 'loadData':
        case 'loadMultiData':
          return ControlType.AUTOSUGGEST;
        case 'choice':
          return ControlType.SWITCH;
        case 'techSupportBlock':
          return ControlType.TECH_SUP_BLOCK;
        case 'emailNotifications':
          return ControlType.EMAIL_NOTIFICATIONS;
        case 'timeInput':
          return ControlType.TIME_INPUT;
      }
    }
    switch (meta.dataType) {
      case DataType.TEXT:
        return ControlType.TEXTAREA;
      case DataType.STRING:
      case DataType.LONG:
      case DataType.DOUBLE:
        return ControlType.INPUT;
      case DataType.DATE:
        return ControlType.DATE;
    }
    return ControlType.UNKNOW;
  };

  getSpan(className: string) {
    const num: any | null = className.match(/^span([0-9]{1,2})$/);
    if (num !== null) {
      let value: number = parseInt(num[1], 10);
      if (!isNaN(value)) {
        // ant grid 1-24
        value = value * 2;
        return value > 0 && value < 25 ? value : 24;
      }
    }
    return null;
  }

  isCustom(component: any): boolean {
    return component?.jsonConfig?.customFilter === true;
  }

  isHidden(component: any) {
    return isHiddenComponent(component);
  }

  createControlElement(type: ControlType, props: any) {
    //     Метод отображения - тип отображения данных. Доступно одно из следующих значений:
    // − dateTimePicker – вывод даты в формате «dd.mm.yyyy hh:mm:ss»;
    // − textarea – многострочное текстовое значение;
    // − href – отображение наименования поля в виде гиперссылки;
    // − currency – отображение валют. Разделитель тысяч - пробел, разделитель дробной части - запятая, отображение 2-х знаков после запятой (для типа данных FLOAT, DOUBLE).
    // − select – выбор одного значения из справочника;
    // − multiselect – выбор одного или нескольких значений из справочника;
    // − loadData – быстрый поиск значений справочника. В основном использует`ся для справочников, которые содержат большое количество данных;
    // − loadMultiData – быстрый поиск одного или нескольких значений справочника. В основном используется для справочников, которые содержат большое количество данных.

    const controlProps =
      type === ControlType.CARD_TABLE
        ? props
        : { ownProps: { ...props }, refreshID: props.refreshID };

    const controlComponent = type && this.controlMap[type];

    if (controlComponent) return React.createElement(controlComponent, controlProps);

    const unknownControlMessage = `Not implimented type:
      ${type}
      ${JSON.stringify(props)}`;

    return React.createElement('span', {}, unknownControlMessage);
  }

  updateMultipleForSelect(component: FormComponentMeta & BaseComponentInterface) {
    if (this._getControlType(component) === ControlType.SELECT) {
      if (component.templateName === 'dynamicMultiselect') {
        component.fetchParamMultiple = true;
      }
      if (component.templateName === 'multiselect' || component.renderer === 'multiselect') {
        component.multiple = true;
      }
    }
  }

  // returns true if modified
  modifyComponent(propName: string, componentProps: JsObject): boolean {
    const component = this.componentMap[propName];
    if (!component) return false;

    let modified: boolean = MiscUtils.addToObjectRecursive(component, componentProps);

    const hidden = this.isHidden(component);
    if (component.hidden !== hidden) {
      component.hidden = hidden;
      modified = true;
    }

    return modified;
  }

  modifyComponents(componentPropsMap: JsObjectNested): boolean {
    let modified: boolean = false;

    Object.keys(componentPropsMap).forEach((propName: string) => {
      const componentModified: boolean = this.modifyComponent(
        propName,
        componentPropsMap[propName]
      );
      if (componentModified) modified = true;
    });

    return modified;
  }

  modifyComponentsAndCloneIfModified(componentPropsMap: JsObjectNested): ControlFactory {
    const modified: boolean = this.modifyComponents(componentPropsMap);

    if (modified) return this.clone();
    else return this;
  }

  disableComponents(): void {
    Object.values(this.componentMap).forEach((component: any) => (component.readonly = true));
  }

  create({ component }: CompomentCreateOptions, isChunked?: boolean, useKB?: string) {
    const { required, cssClass, label, Title, propName, jsonConfig, params, hidden } = component;

    if (hidden) return null;

    const requiredMessage = params?.requiredMessage;

    const labeled: boolean | undefined = jsonConfig?.cardDisplay?.labeled;
    const span = this.getSpan(component.cssClass);
    const type: ControlType = this._getControlType(component);

    // Обновляем компоненты формы, если тип контрола - SELECT.
    this.updateMultipleForSelect(component);

    const controlElement = this.createControlElement(type, {
      component,
      controlType: type,
      refreshID: propName,
      withRowsSelect: Boolean(useKB),
    });

    const requiredRule: any = {
      required: true,
      message: requiredMessage || i18n.t("requiredField"),
    };

    const rules = [];
    required && rules.push(requiredRule);
    if (!!jsonConfig?.reg) {
      rules.push({
        pattern: new RegExp(jsonConfig.reg.check),
        message: jsonConfig.reg.message
      })
    }

    const formItemProps: any = {
      children: controlElement,
      name: propName,
      label: labeled === false ? ' ' : label || Title,
      rules: rules,
    };

    if (isChunked) {
      //@ts-ignore
      component.isChunked = true;
    }

    switch (type) {
      case ControlType.FILES_LIST:
        // Добавляем текст по умолчанию для валидации, если requiredMessage не задано.
        if (required) {
          formItemProps.rules[0].message = requiredMessage || 'Добавьте вложение';
        }
        formItemProps.noStyle = !required;
        // Отображаем label, если labeled равен true.
        formItemProps.label = labeled === true ? label || Title : ' ';
        break;
      case ControlType.CHECKBOX:
        formItemProps.label = labeled === true ? label || Title : ' ';
        formItemProps.valuePropName = 'checked';
        break;
      case ControlType.SWITCH:
        formItemProps.label = labeled === true ? label || Title : ' ';
        break;
      case ControlType.CARD_TABLE:
        delete formItemProps.label;
        break;
      case ControlType.TIME_INPUT:
        formItemProps.label = null;
        break;
      case ControlType.SELECT:
        // Получаем имя связанного поля SELECT.
        const secondProp = getLinkedSelectProp(component);
        // Добавляем правило валидации для связанного поля
        if (secondProp && required) {
          formItemProps.rules[0] = (form: FormInstance) => ({
            ...requiredRule,
            transform: () => form.getFieldValue(secondProp),
          });
        }

        break;
    }

    if (isChunked) {
      return React.createElement(InputAutosuggest, {
        ownProps: { component: component, controlType: ControlType.AUTOSUGGEST },
      });
    }

    const colProps: any = {
      children: React.createElement(FormItem, formItemProps),
    };
    if (span) {
      colProps.span = span;
    } else {
      colProps.className = cssClass;
    }

    return React.createElement(Col, colProps);
  }
}

export default ControlFactory;
