import React, { useContext, useEffect, useRef, useState } from 'react';
import Title from 'components/layout/content/title/Title';
import styles from './reports.module.css';
import stylesSelect from '../../components/controls/SelectControl/SelectControl.module.css';
import stylesDatePicker from '../../components/controls/InputDate/InputDate.module.css';
import { Button, Collapse, DatePicker, Form, Select, Spin } from 'antd';
import entityEditorDetalisStyle from '../entityEditor/Tabs/EntityEditorDetails.module.css';
import AccordionIcon from '../../components/icons/AccordionIcon';
import { valueForPeriod, valuesForTypeRequests } from './utils';
import axios from 'axios';
import FormatUtils from '../../utils/FormatUtils';
import { getPopupContainer } from 'components/controls/SelectControl/SelectControl';
import stylesButtons from '../entityEditor/Buttons/EntityEditorButtons.module.css';
import { AxiosResponseExt } from '../../interfaces/AxiosResponseExt';
import DropdownIcon from '../../components/icons/DropdownIcon';
import notification from 'components/messages/notification';
import FloatingChart from './FloatingChart';
import { ItemDataTooltip } from 'utils/types';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake';
import GlobalLoaderContext from '../../components/layout/loader/GlobalLoaderContext';
import { TDocumentDefinitions, Content } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

interface ReportProps {
  width: number;
  isRA?: boolean;
}

const WaterfallChartPage: React.FC<ReportProps> = (props) => {
  const { Panel } = Collapse;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { width } = props;
  const [period, setPeriod] = useState<string>('');
  const [date, setDate] = useState<any>();
  const [requestType, setRequestType] = useState<string>('engineeringTechnical');
  const [dataIntegralIndicatorsForPeriod, setDataIntegralIndicatorsForPeriod] = useState<
    ItemDataTooltip[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const [exportPdfEnabled, setExportPdfEnabled] = useState<boolean>(false);
  const ref: any = useRef(null);
  const [dataFloatingChart, setDataFloatingChart] = useState<any>([]);
  const globalLoader = useContext(GlobalLoaderContext);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const dropdownIcon = (
    <DropdownIcon
      onClick={() => {
        if (ref && ref.current && ref.current.focus && ref.current.blur) {
          ref.current.focus();
        }
      }}
      className={stylesSelect.icon}
    />
  );

  const onChangePeriod = (event: any) => {
    setPeriod(event);
    valueForPeriod(t).forEach((item: any) => {
      if (event === item.value) {
        setDate(item.date);
        return;
      }
    });
  };

  const onChangeDate = (event: any) => {
    setDate(event);
  };

  const resetForm = () => {
    setDate('');
    setPeriod('');
    setRequestType('');
    setDataIntegralIndicatorsForPeriod([]);
  };

  const onChangeRequestType = (event: any) => {
    setRequestType(event);
  };

  const getReportByPeriod = () => {
    if (date) {
      globalLoader.setLoading(true);
      const bodyFormData = new FormData();
      bodyFormData.append(
        'start',
        new Date(new Date(date[0]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      bodyFormData.append(
        'end',
        new Date(new Date(date[1]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      axios
        .post(
          process.env.REACT_APP_TYPE === 'irk'
            ? '/ByPeriodEngineeringTechnicalRequestReport'
            : props.isRA
            ? '/ByPeriodRAReport'
            : '/ByPeriodRequestsReport',
          bodyFormData
        )
        .then((res: AxiosResponseExt) => {
          if (!res.error && res.data) {
            setDataIntegralIndicatorsForPeriod(res.data.integralData);
          }
        })
        .catch((err) => {
          notification.error(`${t('errorHappened')} ${err}`);
        })
        .finally(() => {
          globalLoader.setLoading(false);
        });
    } else {
      notification.error(t('selectAPeriod'));
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    return current && current > moment().endOf('day');
  };

  const newDates =
    date &&
    date.map((date: Date) => {
      const newDate: Date = new Date(date);
      const options: any = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      return newDate.toLocaleDateString('ru', options);
    });
  const wrapDate: HTMLDivElement | null = document.querySelector('.wrapDate');

  const getPdf = () => {
    setExportPdfEnabled(true);
    setLoading(true);
    if (wrapDate !== null) {
      const chartFloating: HTMLElement | null = document.querySelector('.chartFloating');
      if (!!newDates) wrapDate.textContent = `Период с ${newDates[0]} по ${newDates[1]}`;
      if (chartFloating !== null) {
        html2canvas(chartFloating)
          .then((chartFloatingCanvas: HTMLCanvasElement) => {
            html2canvas(wrapDate)
              .then((dateCanvas) => {
                const imgDate: Content = {
                  image: dateCanvas.toDataURL(),
                  width: 200,
                  style: {
                    alignment: 'center',
                    marginBottom: 20,
                  },
                };
                const chartFloating: Content = {
                  image: chartFloatingCanvas.toDataURL(),
                  width: 700,
                  style: {
                    alignment: 'center',
                  },
                };
                const documentDefinition: TDocumentDefinitions = {
                  content: [imgDate, chartFloating],
                  defaultStyle: {
                    font: 'NimbusSans',
                  },
                  // pageSize: "A4",
                  pageOrientation: 'landscape',
                  pageMargins: [40, 60, 40, 60],
                };

                const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
                pdfDocGenerator.download(
                  `${t("graphForPeriod")} ${date[0].format('DD.MM.YYYY')}-${date[1].format(
                    'DD.MM.YYYY'
                  )}.pdf`
                );
              })
              .catch((err) => {
                setLoading(false);
                notification.error(`${t('errorHappened')}: ${err}`);
              })
              .finally(() => {
                setLoading(false);
                setExportPdfEnabled(false);
              });
          })
          .catch((err) => {
            setLoading(false);
            notification.error(`${t('errorHappened')}: ${err}`);
          });
      } else {
        setLoading(false);
        setExportPdfEnabled(false);
      }
    } else {
      setLoading(false);
      setExportPdfEnabled(false);
    }
  };

  const handleNioKseReport = () => {
    if (date) {
      setLoading(true);
      setExportEnabled(true);
      const bodyFormData: any = new FormData();
      bodyFormData.append(
        'start',
        new Date(new Date(date[0]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      bodyFormData.append(
        'end',
        new Date(new Date(date[1]._d).toString().split('GMT')[0] + ' UTC').toISOString()
      );
      axios
        .post('/NioKseReport', bodyFormData)
        .then((response: AxiosResponseExt) => {
          if (response.error) {
            notification.error({
              text: response.error,
              width,
            });
          } else {
            const element = document.createElement('a');
            element.setAttribute('href', `${response.data.file}`);
            element.setAttribute('target', '_blank');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            setExportEnabled(true);
            notification.success({
              text: response.data.message,
              width,
            });
          }
        })

        .finally(() => {
          setExportEnabled(false);
          setLoading(false);
        });
    } else {
      notification.error({
        text: 'Укажите период',
        width,
      });
    }
  };

  useEffect(() => {
    if (dataIntegralIndicatorsForPeriod.length > 0 && date !== undefined) {
      setDataFloatingChart([
        [0, dataIntegralIndicatorsForPeriod[0]?.rows[0]?.values[0]],
        [
          dataIntegralIndicatorsForPeriod[0]?.rows[0]?.values[0],
          Number(dataIntegralIndicatorsForPeriod[0]?.value),
        ],
        [0, Number(dataIntegralIndicatorsForPeriod[0]?.value)],
        [
          Number(dataIntegralIndicatorsForPeriod[0]?.value),
          Number(dataIntegralIndicatorsForPeriod[0]?.value) -
            dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0],
        ],
        [
          Number(dataIntegralIndicatorsForPeriod[0]?.value) -
            dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0],
          Number(dataIntegralIndicatorsForPeriod[0]?.value) -
            dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
            dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0],
        ],
        [
          Number(dataIntegralIndicatorsForPeriod[0]?.value) -
            dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
            dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0],
          Number(dataIntegralIndicatorsForPeriod[0]?.value) -
            dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
            dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0] -
            dataIntegralIndicatorsForPeriod[4]?.rows[0].values[0],
        ],
        [
          Number(dataIntegralIndicatorsForPeriod[0]?.value) -
            dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
            dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0] -
            dataIntegralIndicatorsForPeriod[4]?.rows[0].values[0],
          Number(dataIntegralIndicatorsForPeriod[0]?.value) -
            dataIntegralIndicatorsForPeriod[2]?.rows[0].values[0] -
            dataIntegralIndicatorsForPeriod[2]?.rows[1].values[0] -
            dataIntegralIndicatorsForPeriod[4]?.rows[0].values[0] -
            dataIntegralIndicatorsForPeriod[4]?.rows[1].values[0],
        ],
      ]);
    }
  }, [dataIntegralIndicatorsForPeriod]);

  return (
    <div className={styles.wrapper}>
      <Spin spinning={loading}>
        <div className={'js-parentNode'}>
          <Title title={t('waterfall')} />
          <div className={stylesButtons.container}>
            <div className={stylesButtons.button} onClick={getReportByPeriod}>
              {t('generate')} {process.env.REACT_APP_TYPE === 'csdp' && t('report')}
            </div>
            {process.env.REACT_APP_TYPE === 'irk' && (
              <div className={stylesButtons.button} onClick={resetForm}>
                {t('clear')}
              </div>
            )}

            <Button
              className={stylesButtons.button}
              htmlType='button'
              type={'text'}
              disabled={exportEnabled}
              onClick={handleNioKseReport}
            >
              {t('generateAnUpload')}
            </Button>
            {dataIntegralIndicatorsForPeriod.length > 0 && process.env.REACT_APP_TYPE === 'irk' && (
              <Button
                htmlType='button'
                type={'text'}
                disabled={exportPdfEnabled}
                className={stylesButtons.button}
                onClick={getPdf}
              >
                {t('chartsExport')}
              </Button>
            )}
          </div>
          <Collapse
            className={entityEditorDetalisStyle.collapse}
            ghost={true}
            expandIcon={({ isActive }) => <AccordionIcon active={Boolean(isActive)} />}
            defaultActiveKey={[1, 2, 3, 4]}
          >
            <Panel key={'1'} header={props.isRA ? t('reportParameters') : t('basicInformation')}>
              <Form layout={'horizontal'} className={styles.form}>
                {process.env.REACT_APP_TYPE === 'irk' && (
                  <Form.Item className={styles.inputWrap}>
                    <span className={styles.labelInput}>{t('requestType')}</span>
                    <Select
                      ref={ref}
                      suffixIcon={dropdownIcon}
                      getPopupContainer={getPopupContainer}
                      onChange={onChangeRequestType}
                      value={requestType}
                      dropdownClassName={stylesSelect.dropdown}
                      className={stylesSelect.input}
                    >
                      {valuesForTypeRequests(t).map((value: any, i: number) => {
                        return (
                          <Option key={`value_${i}`} value={value.value}>
                            {value.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                <>
                  {' '}
                  <Form.Item className={styles.inputWrap}>
                    <span className={styles.labelInput}>{t('period')}</span>
                    <Select
                      ref={ref}
                      suffixIcon={dropdownIcon}
                      defaultValue={''}
                      getPopupContainer={getPopupContainer}
                      dropdownClassName={stylesSelect.dropdown}
                      className={stylesSelect.input}
                      onChange={onChangePeriod}
                      value={period}
                    >
                      {valueForPeriod(t).map((value: any, i: number) => {
                        return (
                          <Option key={`value_${i}`} value={value.value}>
                            {value.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item className={styles.inputWrap}>
                    <RangePicker
                      className={stylesDatePicker.input}
                      disabled={period !== 'arbitraryPeriod'}
                      placeholder={['', '']}
                      onChange={onChangeDate}
                      disabledDate={disabledDate}
                      value={date}
                      format={FormatUtils.dateFormat}
                    />
                  </Form.Item>
                </>
              </Form>
            </Panel>
            <div className={`${styles.wrapDate} wrapDate`} />
            <Panel key={'2'} header={t('chart')}>
              <div className={styles.wrapperChart}>
                {dataIntegralIndicatorsForPeriod.length > 0 ? (
                  <>
                    {process.env.REACT_APP_TYPE === 'irk' && (
                      <div className='chartFloating'>
                        {' '}
                        <FloatingChart
                          period={period}
                          width={width}
                          dataFloatingChart={dataFloatingChart}
                          dataTooltip={dataIntegralIndicatorsForPeriod}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className={i18n.language === 'en' ? styles.emptyEN : styles.empty}>
                    {t('toBuildTheChartConfigureTheMainInformationBlockAndClickGenerate')}
                    {process.env.REACT_APP_TYPE === 'csdp' && ` ${t('report')}`}"
                  </div>
                )}
              </div>
            </Panel>
          </Collapse>
        </div>
      </Spin>
    </div>
  );
};

export default WaterfallChartPage;
